export const EVENTS = {
  USER_CLICKS_SUBMIT: 900,
  SUBMISSION_SUCCESS: 901,
  SENT_TO_WIXDATA_SERVER_SUCCESS: 904,
  SUBMISSION_FAILURE: 902,
  PUBLISH_SITE_PANEL_OPENED: 979,
  PUBLISH_SITE_PANEL_CLOSED: 980,
  CAPTCHA_ACTION: 869,
  USER_CLICKS_NAVIGATION_BUTTONS: 892,
}

export const VIEWER_ORIGIN = 'Viewer'
